import 'sticky-kit/dist/sticky-kit';

const stickykitInit = () => {
  const viewport = $(window).width();
  if (viewport > 576) {
    $('#sticky-wp').stick_in_parent();
  } else {
    $('#sticky-wp').trigger('sticky_kit:detach');
  }
};

stickykitInit();

$(window).on('resize', () => {
  stickykitInit();
});
