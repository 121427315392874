import 'jquery-parallax.js';

$('.parallax-wp').each((_, elm) => {
  $(elm).parallax({
    positionY: 'top',
  });
});

$(window).on('resize', () => {
  $(window).trigger('resize.px.parallax');
});
