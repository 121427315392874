const navigationMobile = () => {
  $('#button--mobile').on('click', () => {
    $('body').toggleClass('menu-open');
    $('#navigation-main').toggle();
  });
};

const navigationSize = () => {
  const position = $(window).scrollTop();
  if (position > 220) {
    $('body').addClass('header-small');
  } else {
    $('body').removeClass('header-small');
  }
  $(window).on('scroll', (e) => {
    if ($(e.currentTarget).scrollTop() > 220) {
      $('body').addClass('header-small');
    } else {
      $('body').removeClass('header-small');
    }
  });
};

const navigationDropdown = () => {
  const viewport = $(window).width();
  if (viewport < 576) {
    $('#navigation-main li[data-navsub] > a').on('click', (e) => {
      $(e.currentTarget).next().toggle();
      return false;
    });
  } else {
    $('#navigation-main li[data-navsub] > a').on('click', (e) => {
      const navSub = $(e.currentTarget).parent();
      const isFocus = navSub.hasClass('focus');
      $('#navigation-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      e.stopPropagation();
      return false;
    });
    $('html').on('click', () => {
      $('#navigation-main li[data-navsub]').removeClass('focus');
    });
  }
};

const scrollTop = () => {
  const scrollToDomElm = $('#button--scroll-top');
  scrollToDomElm.on('click', () => {
    $('html, body').animate({
      scrollTop: 0,
    }, '500');
    return false;
  });
};

const chatBot = () => {
  let buttonChatBot = $('#button-chat-bot');

  buttonChatBot.fadeOut(0);
  //$('#talkr-container').css('display', 'none');

  buttonChatBot.on('click', () => {
    $('#talkr-container').css('visibility', 'visible');
    $('#talkr-container .kwalys-open-chatbox-button').click();
  });

  $(window).on('scroll', (e) => {
    if ($(e.currentTarget).scrollTop() > 220) {
      buttonChatBot.fadeIn(300);
      $('#talkr-container').fadeIn(300);
    } else {
      buttonChatBot.fadeOut(300);
      $('#talkr-container').fadeOut(300);
    }
  });
};

$(document).ready(function () {
    if( $('.compteur-recap').length>0 ){
        var padding = 5 - $('.compteur-recap').val().length
        $('.compteur-digit').each(function( index ) {
            $('.compteur-digit').eq( index + padding).val($('.compteur-recap').val()[index]);
        });
    }
})
$('.compteur-digit').keyup(function(event) {
    // Si l'input actuel est rempli, passer à l'input suivant
    if ($(this).val().length === 1) {
        $(this).next('input').focus();
    }
    var compteur = "";
    $('.compteur-digit').each(function( index ) {
        compteur = compteur + $( this ).val() ;
    });
    $('.compteur-recap').val(compteur);
});

navigationMobile();
navigationDropdown();
navigationSize();
scrollTop();
chatBot();
